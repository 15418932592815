@import '../../../../../../styles/variables.scss';
@import '../../../../../../styles/mixins.scss';

.entry {
    &Area {
        flex: 5;
    }
    &Sidebar {
        flex: 3;
        margin-top: 2rem;
    }
}

.loginCode {
    font-size: 1.4rem;
    strong {
        font-weight: bold;
    }
}
.chapStatus {
    align-items: center;
    display: flex;
    color: #8e8e8e;
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 20px;
}

.statusIcon {
    margin-right: 5px;
}
@include breakpoint-up(sm) {
    .restoreOpts {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        p {
            margin-top: 0;
        }
    }
}

@include breakpoint-up(md) {
    .entry {
        &Container {
            display: flex;
        }
        &Sidebar {
            margin-left: 2rem;
            margin-top: 0;
        }
    }
}
