.gemButton {
    align-items: center;
    display: flex!important;
    justify-content: center;
    min-width: 19.5rem;
    &Img {
        margin-right: .5rem;
        width: 2rem;
    }
    &Confirmed {
        height: 2.1rem;
        width: 2.1rem;
    }
}