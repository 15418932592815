@import '../../../../../../styles/variables.scss';
@import '../../../../../../styles/mixins.scss';

.saveActions {
    display: flex;
    margin-top: 1rem;
    flex-wrap: wrap;
    li {
        margin-bottom: .5rem;
    }
    &Back {
        margin-left: auto;
        width: 100%;
    }
}


@include breakpoint-up(sm) {
    .saveActions {
        &Back {
            width: auto;
        }
    }
}
