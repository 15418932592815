@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';

.utilities {
    &Search {
        position: relative;
        width: 100%;
        input {
            padding: 1rem;
        }
        &Icon {
            position: absolute;
            top: 0.8rem;
            right: 1rem;
            color: #bbb;
        }
    }
    &Btn {
        line-height: 1;
        margin-bottom: 0!important;
        display: flex;
        align-items: center;    
    }
}

.writesWrapper {
    padding-bottom: 10rem;
}
.writesFooter {

    background: #e5e5e5;

    background: #F7F7F7;
    position: fixed;
    bottom: 0;
    right: 0;
    left: 100px;
    padding: 2rem;
    display: flex;
    justify-content:flex-end;
    align-items: center;
}
.write {
    text-align: center;
    margin: 2rem 0;
    padding: 0;
    border: none;

    &Content {
        font-size: 3rem;
        margin-bottom: 0;
        border-radius: 1rem 1rem 0 0;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 3rem 1rem;
        flex-direction: column;
        background: $primary;
    }

    &Title {
        font-weight: bold;
        margin-bottom: 0;
    }
    &TitleClass {
        font-weight: normal;
        font-size: 1.2rem;
    }

    &SubTitle {
        color: $body-color;
        padding: 1rem;
    }

    &Type {
        align-items: center;
        background: #FFFFFF;
        border: 1px solid $gray-1;
        border-radius: 2.1rem;
        box-shadow: 0 0.2rem .5rem 0 rgba(0, 0, 0, 0.16);
        color: #C33764;
        display: flex;
        height: 4.2rem;
        justify-content: center;
        left: calc(50% - 2.1rem);
        position: absolute;
        top: -2.1rem;
        width: 4.2rem;
    }

}


.addTaskBtn {
    border: 0;
    text-align: center;
    margin: 0 0 2rem;
    padding: 0;
    display: block;

    &Content {
        align-items: center;
        display: flex;
        flex-direction: column;
        font-size: 1.8rem;
        font-weight: bold;
        justify-content: center;
        padding: 3rem 1rem;
    }

    &Icon {
        color: $gray-2;
        height: 5.5rem;
        width: 5.5rem;
    }
}

@include breakpoint-up(sm) {
    .write {
        &Content {
            min-height: 14rem;
        }
    }
}




@include breakpoint-up(sm) {
    .addTaskBtn {
        margin: 2rem 0;

        &Content {
            height: 18.5rem;
        }
    }

}

.lockedWrites {
    text-align: center;
    background: #BBB;
    color: #fff;
    padding: 2rem;
    border-radius: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .lockIcon {
        position: absolute;
        top: 2rem;
        right: 2rem;
    }
    h1 {
        font-weight: bold;
    }
}
.isp {
    border-radius: 1rem;
    margin: 2rem 0;
    padding: 1rem 2rem;
    display: grid;
    grid-template-columns: 1fr 500px;
    gap: 2rem;
    justify-content: space-between;
    align-items: center;
    color: #fff;
    background: #FD1E69;
    cursor: pointer;
    min-height: 170px;
    &Title {
        font-size: 2rem;
    }
    
}

.selector {
    display: grid;
    grid-template-columns: 480px 1fr;
    gap: 2rem;
    align-items: flex-start;
}
.starterItem {
    border-radius: 1rem;
    background: #C3C3C3;
    margin-bottom: 1.4rem;
    color: #fff;
    font-weight: bold;
    text-align: center;
    padding: 1rem;
    font-size: 1.8rem;
        box-shadow: 0 0.1rem .3rem 0 rgba(0, 0, 0, 0.16);
    cursor: pointer;
    &Selected {
        background: #021781;
    }
}

.selectedStarterHead {
    display: grid;
    grid-template-columns: 1fr 120px;
    gap: 2rem;
}