.criterion {
    align-items: center;
    display: flex;
    font-size: 2rem;
    font-weight: bold;
    justify-content: space-between;
}
.addCriterionButton {
    align-items: center;
    border: 1px dashed #9A9A9A;
    cursor: pointer;
    display: flex;
    font-size: 2rem;
    font-weight: bold;
    margin-top: 20px;
    padding: 10px;
    width: 100%;
    &:hover {
        background: #F2F2F2;
    }
}
.addCriterionButtonIcon {
    margin-right: 10px;
}
