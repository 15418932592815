.studentsPrint {
    background: #FFFFFF;
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 200;
    overflow-y: auto;
}

.noPrint {
    background: #f2f2f2;
    margin-bottom: 2rem;
    padding: 1rem;

    &Content {
        max-width: 80rem;
        margin: 0 auto;
    }
}
.lead {
    font-size: 1.4rem;
}
.subtitle {
    font-size: 1.6rem;
    font-weight: bold;
}
.student {
    border-bottom: 1px solid #F2F2F2;
    padding: 30px 0;
    max-width: 80rem;
    margin: 20px auto;
    p {
        font-size: 1.2rem;
    }



    &Head {
        display: flex;
        align-items: flex-start;
        margin-bottom: 3rem;
        &Main {
            flex: 1;
            padding-right: 2rem;
        }
        &Logo {
            margin-bottom: 2rem;
        }
        &Name {
            font-weight: bold;
            font-size: 2rem;
        }
        &Published {
            font-size: 1.8rem;
        }
        &Brian {
            flex-basis: 22rem;
            flex-shrink: 0;
            max-width: 22rem;
        }
    }

    &Funds,
    &Book,
    &OrderDelivery{
        border: 1px solid #E1E1E1;
        padding: 1.5rem;
        margin-bottom: 2rem;
        border-radius: 2rem;
        display: flex;
    }
    &Funds {
        align-items: center;
        &Img {
            flex-basis: 7.5rem;
            max-width: 7.5rem;
            flex-shrink: 0;
            margin-right: 2rem;
        }
    }


    &Book {
        &Main {
            flex:1;
        }
        &Img {
            flex-basis: 24rem;
            max-width: 24rem;
            flex-shrink: 0;
            margin-left: 2rem;
        }
        &List {
            list-style-type: disc;
            list-style-position: inside;
            font-weight: bold;
            margin-bottom: 1rem;
        }
    }


    &Order {
        display: flex;
        align-items: flex-start;

        &Main {
            flex: 1;
            padding-right: 1.5rem;
        }
        &Title {
            font-weight: bold;
            font-size: 1.8rem;
            padding-bottom: 1rem;
            border-bottom: 1px solid #000;
            margin-bottom: 1rem;
        }
        &Subtitle {
            font-size: 1.8rem;
            font-weight: bold;
        }
        &Img {
            max-width: 11rem;
            margin-right: 2rem;
        }

        &Url {
            display: flex;
            align-items: flex-start;
            margin-top: 1.2rem;
            &Title {
                font-size: 1.8rem;
            }
            &Body {
                flex: 1;
                margin-top: 1.5rem;
                font-size: 2.8rem;
            }
        }
        &Side {
            flex-basis: 20rem;
            max-width: 20rem;
            flex-shrink: 0;
            text-align: center;
        }
        &Delivery {
            margin-bottom: 2rem;
            display: block;
            text-align: left;
        }
        &Logo {
            margin: auto;
        }
    }
}


@media print {
    @page {
        size: letter portrait;
        margin: 1cm;
    }

    body {
        background: #fff;
    }

    .studentsPrint {
        position: static;
    }

    .student {
        border-bottom: none;
        padding: 0;
        margin: 0 auto;
        max-width: 60rem;
        margin-top: 2rem;
    }

    .printSheet {
        margin: 0 auto;
        overflow: hidden;
        position: relative;
        box-sizing: border-box;
        break-after: page;
        page-break-after: always;
    }
}