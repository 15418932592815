@import '../../styles/variables';
@import '../../styles//mixins';

.purchase {
    &Recommended {
        background: #C6CBB5;
        border-radius: 2rem;
        padding: 5px;
        margin-bottom: 2rem;
        &Title {
            padding: 1.2rem 2rem;
            text-align: center;
            margin-bottom: 0;
            font-weight: bold;
        }
        &Content {
            background: #3A3A3A;
            padding: 1rem;
            border-radius: calc(1rem - 2px);
            color: #fff;
        }
    }
}
.panel {
    padding: 2rem;
    border-radius: 1rem;
    background: $gray-1;
    &Sep {
        width: calc(100% + 4rem);
        background: #fff;
        height: 1px;
        margin: 2rem -2rem;
    }
}
.listItem {
    li {
        margin-bottom: .5rem;
        padding-left: .5rem;
        list-style: disc;
        margin-left: 2rem;
    }
}
.about {
    display: flex;
    align-items: flex-start;
    &Content {
        flex: 1;
        margin-right: 2rem;
    }
}

.klsWrapper {
    display: flex;
    flex-wrap: wrap;
}
.klsItem {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    margin-right: 1rem;
    label {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }
    input {
        margin-right: .5rem;
    }
}

.klsSelected {
    margin-bottom: .7rem;
    padding-bottom: .7rem;
    border-bottom: 1px solid $gray-1;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.totals {
    li {
        display: flex;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: .7rem;
    }
}

@include breakpoint-up(sm) {
    .klsItem {
        flex-basis: calc(50% - 1rem);
        max-width: calc(50% - 1rem);
    }
}

@include breakpoint-up(md) {
    .klsItem {
        flex-basis: calc(25% - 1rem);
        max-width: calc(25% - 1rem);
    }
}

.selectPackDisabled {
    opacity: .5;
}
