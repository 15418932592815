@import '../../../../../../styles/variables.scss';

.setup {
    &Guidance {
        border: 1px solid #979797;
        height: 100px;
        padding: 10px;
        resize: none;
        width: 100%;
    }
}

.vocabField {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 1rem;
}
.vocabItem {
    background:$secondary;
    border-radius: 1rem;
    color: #fff;
    display: flex;
    margin: 0 0.5rem 0.5rem 0;
    align-items: center;
    font-size: 1.4rem;
    font-weight: 800;
    padding: .5rem 1rem;

    &Delete {
        appearance: none;
        padding: 0;
        border: none;
        margin-left: .8rem;
        width: 1.6rem;
        height: 1.6rem;
        line-height: 1.3;
        border-radius: 1rem;
        cursor: pointer;

        &:focus {
            outline: none;
        }

        &:hover {
            color: rgba(0, 0, 0, 0.4);
        }
    }
}