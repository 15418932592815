.orderList {
    &Action {
        &Input {
            width: 8rem!important;
            text-align: center;
        }
        &Extra {
            width: 12rem!important;
            display: block;
            text-align: center;
            padding-right: 1rem;
        }
        margin-left: auto;
        display: flex;
    }
}

//write to raise
.wtrCopiesHeader {
    margin-bottom: 1rem;
    display: flex;
    justify-content: flex-end;
    &Parent {
        width: 12rem;
        padding-right: 1rem;
    }
    &Extra {
        width: 8rem;
    }
}
.wtrTotalsFooter {
    font-weight: bold;
    display: flex;
    margin-bottom: 2rem;
    justify-content: space-between;
}
