@import '../../../../../../styles/variables.scss';

.completed {
    &Check {
        align-items: center;
        cursor: pointer;
        display: flex;
        margin: .5rem 0;
        svg {
            margin-right: .5rem;
        }
    }
}

.intro {
    padding: 2rem;
    background: lighten($gray-1, 5%);
    margin-bottom: 2rem;
    display: flex;
    &Icon {
        margin: .3rem 1rem 0 0;
    }
}

.printCheckWrapper {
    margin-top: 3rem;
    border-radius: 1rem;
    padding: 2rem;
    background: #EDEDED;
}
.printForeword {
    background: #fff;
    padding: 1.5rem;
    border: 1px solid #696868;
}


//WTR book
.wtrBanner {
    border-radius: 2rem;
    background: linear-gradient(#20364B, #1D2671);
    color: #fff;
    display: flex;
    margin: 1rem 0 3rem 1rem;
    align-items: center;
    position: relative;
    padding: 1.4rem;

    &Img {
        margin-right: 2rem;
        max-width: 12rem;
        position: absolute;
        left: -1rem;
        bottom: 0rem;
        width: 12rem;

    }
    &Cover {
        position: absolute;
        right: 1rem;
        top: -2rem;
        width: 12rem;
    }

    &Content {
        padding-left: 12rem;
        padding-right: 12rem;
    }

    &Logo {
        max-width: 29rem;
    }

    &Title,
    &Subtitle {
        margin-bottom: 0;
        font-weight: bold;
    }

    &Title {
        font-size: 2.4rem;
    }

    &Subtitle {
        font-size: 1.8rem;
    }
}

.brianCircleSide {
    max-width: 24rem;
    margin-top: 3rem;
}

.wtrTodo {
    li {
        margin-bottom: 1.2rem;
        display: flex;
        svg {
            margin-right: 1rem;
        }
    }
}