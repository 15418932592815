@import '../../../../styles/variables.scss';
@import '../../../../styles/mixins.scss';


.chapter {
    display: flex;
    flex: 1;
    justify-content: space-between;
    flex-direction: column;
    max-width: 100rem;
    &Content {
        flex: 1;
        margin-right: 0;
    }
}


@include breakpoint-up(lg) {
    .chapter {
        flex-direction: row;
        &Content {
            margin-right: 2rem;
        }
    }
}

.editGuidance {
    padding: 0 2rem 2rem 2rem;
    border-top: 1px solid #E1E1E1;
    width: calc(100% + 4rem);
    margin-left: -2rem;
    margin-right: -2rem;
    text-align: left;
    margin-top: 2rem;
}


.vocabField {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 1rem;
}

.vocabItem {
    background: $secondary;
    border-radius: 1rem;
    color: #fff;
    display: flex;
    margin: 0 0.5rem 0.5rem 0;
    align-items: center;
    font-size: 1.4rem;
    font-weight: 800;
    padding: .5rem 1rem;

    &Delete {
        appearance: none;
        padding: 0;
        border: none;
        margin-left: .8rem;
        width: 1.6rem;
        height: 1.6rem;
        line-height: 1.3;
        border-radius: 1rem;
        cursor: pointer;

        &:focus {
            outline: none;
        }

        &:hover {
            color: rgba(0, 0, 0, 0.4);
        }
    }
}