@import '../../../../styles/variables.scss';
@import '../../../../styles/mixins.scss';

.todoList {
    li {
        margin-bottom: 1rem;
    }

    a,
    button {
        display: flex;
        align-items: center;
    }

    svg {
        margin-right: 1rem;
        color: #E1E1E1;
    }
}

.resources {

    li {
        font-weight: bold;
        font-size: 1.6rem;
        color: $body-color;
        margin: 1rem -2rem;
        width: calc(100% + 4rem);
        padding: 1rem ;
        border-bottom: 1px solid #E1E1E1;

        &:last-child {
            margin-bottom: 0;
            padding-bottom: 0;
            border-bottom: none;
        }

        a {
            color: inherit;
        }
    }


    &Link {
        display: flex;
        align-items: center;
        width: 100%;
        svg {
            margin-left: auto;
        }
    }

    &Img {
        flex-basis: 10rem;
        max-width: 10rem;
        margin-right: 1rem;
        margin-left: -1rem;
        flex-shrink: 0;
        padding: 0 1.5rem;

        img {
            margin: auto;
            width: 100%;
        }
    }

}