%card-base {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border-radius: 1rem;
  flex: 1 0 0;
}
.card {
    @extend %card-base;
    box-shadow: 0 0.2rem .5rem 0 rgba(0, 0, 0, 0.16);
    &.card-hover {
        color: $body-color;
        transition: all .2s ease-in;
        &:hover {
          border-color: rgba(0, 0, 0, 0.2);
          box-shadow: 0 0.3rem .5rem 0 rgba(0, 0, 0, 0.2);
        }
    }
    .card-header {
        padding: 1.5rem;
    }
    .card-body {
        flex: 1 1 auto;
        padding: 1.5rem;
    }
}

@include breakpoint-up(md) {
  .card {
        .card-body,
        .card-header {
            padding: 2rem;
        }
    }
}

.card-mobile {
    background: #fff;
    padding: 2rem;
    border-radius: 1rem;
    box-shadow: 0 0.2rem .5rem 0 rgba(0, 0, 0, 0.16);
}


@include breakpoint-up(sm) {
    .card-mobile {
        background: none;
        padding: 0;
        box-shadow: none;
    }
    .card-pill {
        background: lighten($gray-1, 13%);
        padding: 2rem;
        border-radius: 4rem;
    }
}

.card-entry,
.card-muted {
    background: lighten($gray-1, 13%);
    padding: 1rem;
    border-radius: .5rem;
    p {
       font-family: $body-font-family!important;
   }
}

@media print {
    .card,
    .card-mobile {
        padding: 0;
    }
}


.panelLight,
.panelLight2 {
    padding: 2rem;
    border-radius: 1rem;
    background: #FAFAFA;
}
.panelLight2 {
    background: #ECECEC;
}