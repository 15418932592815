@import '../../../../styles/variables.scss';
@import '../../../../styles/mixins.scss';

.panel {
    padding: 2rem;
    border-radius: 1rem;
    background: $gray-1;
    &Sep {
        width: calc(100% + 4rem);
        background: #fff;
        height: 1px;
        margin: 2rem -2rem;
    }
}

.about {
    display: flex;
    align-items: flex-start;
    &Content {
        flex: 1;
        margin-right: 2rem;
    }
}