.digital {
    display: grid;
    grid-template-columns: 550px 550px;
    gap: 3rem;
}

.digitalCover {
    background: #fff;
    box-shadow: 0 0.2rem .5rem 0 rgba(0, 0, 0, 0.16);
    &Img {
        max-width: 100%;
        display: block;
    }
    &Footer {
        padding: 3rem;
        display: grid;
        grid-template-columns: 1fr 1fr;
        align-items: center;
        justify-content: space-between;
       
    }
}


.bookModal {
    bottom: 0;
        left: 0;
        position: fixed;
        right: 0;
        top: 0;
        width: 100%;
        z-index: 100;
    &Blanker {
        align-items: center;
        background: rgba(0, 0, 0, 0.5);
        display: flex;
        height: 100%;
        justify-content: center;
        overflow: auto;
        width: 100%;
    }

    &Content {
        max-height: calc(100vh - 40px);
        overflow: initial;
        position: relative;
        width: 100%;
        background: #fff;
        bottom: 0;
        left: 0;
        overflow: auto;
        right: 0;
        top: 0;
        max-width: 600px;
        padding-top: 30px;
    }
    &Inner {
        width: 600px;
        overflow: auto;
    }
   &Close {
       align-items: center;
       border: none;
       cursor: pointer;
       display: flex;
       padding: 0;
       position: absolute;
       right: 5px;
       top: 5px;
       width: 20px;
   }
  
}
.bookPages {
    display: flex!important;
    justify-content: center;
    align-items: center;
}
.bookPage {
    flex-shrink: 0;
    flex-basis: 600px;
    flex-grow: 1;
}
