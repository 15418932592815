a[disabled] {
    pointer-events: none;
}

a {
    text-decoration: none;
    color: inherit;
}
.link {
    cursor: pointer;
}

.link-reverse {
    color: $body-color;
    &:hover {
        color: $primary;
    }
}
.link-underline {
    text-decoration: underline;
}

.btn-reset {
    border: 0;
    padding: 0;
    cursor: pointer;
    text-align: left;
    &:focus {
        outline: none;
    }
}

.btn-small {
    font-size: 1.2rem!important;
    padding: .3rem 1rem!important;
}



.btn-primary {
    background: darken($primary, 10%);
    border-color: darken($primary, 10%);
    text-transform: uppercase;
    color: #fff;
    border-radius: 1rem;
    font-weight: bold;
    padding: 5px 15px;
    cursor: pointer;
    &:hover {
        background: $primary;
    }
}


button:disabled {
    pointer-events: none;
    background: #C3C3C3;
    border-color: #C3C3C3;
}

.btn-outline,
.btn-link {
    border: 1px solid lighten($primary, 10%);
    text-transform: uppercase;
    color: lighten($primary, 10%);
    border-radius: 1rem;
    font-weight: bold;
}

.btn-link {
    color: #fff;
    background: lighten($primary, 10%);
}