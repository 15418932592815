.book {
    display: flex;
    &Main {
        flex: 1;
        padding-right: .5rem;
    }
    &Img {
        min-height: 12rem;
        max-height: 12rem;
        width: auto;
        &Shadow {
            box-shadow: 0 1px 3px rgba(0,0,0,.3);
        }
    }
    &Preview {
        margin-bottom: .5rem;
        margin-right: .5rem;
    }
}
