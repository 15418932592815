@import '../../../../styles/variables';
@import '../../../../styles//mixins';


.promoBanner {
    margin-bottom: 2.4rem;
    margin-top: 3rem;
    box-shadow: 0 0.2rem .5rem 0 rgba(0, 0, 0, 0.16);
    border-radius: 1rem;
    padding: .5rem;
    display: flex;
    color: $body-color;
    line-height: 1;
    position: relative;
    align-items: center;
    background: #fff;
    justify-content: space-between;
   cursor: pointer;
   max-width: 70rem;
    &:focus {
        outline: none;
    }
    &:hover {
        box-shadow: 0 0.3rem .5rem 0 rgba(0, 0, 0, 0.25);
        background: #fff;
    }
    p {
        margin-bottom: .5rem;
    }
    &Img {
        width: 7rem;
        height: auto;
        transform: rotate(-12deg);
        margin-right: 1.2rem;
        position: absolute;
        left: -.4rem;
        top: -1rem;
        box-shadow: 0 0.3rem .5rem 0 rgba(0, 0, 0, 0.25);
    }
    &Body {
        flex: 1;
        min-height: 8rem;
        padding-left: 8rem;
        padding-top: 1rem;
    }
    &Info {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding-right: .5rem;
        p {
            margin: .6rem 0 0 0;
        }
    }
}

.addTaskBtn {
    border: 0;
    text-align: center;
    margin: 0 0 2rem;
    padding: 0;
    &Content {
        align-items: center;
        display: flex;
        flex-direction: column;
        font-size: 1.8rem;
        font-weight: bold;
        justify-content: center;
        padding: 3rem 1rem;
    }
    &Icon {
        color: $gray-2;
        height: 5.5rem;
        width: 5.5rem;
    }
}

.packCompleted {
    align-items: center;
    border-bottom: 1px solid #979797;
    color: $body-color;
    display: flex;
    justify-content: space-between;
    padding: 2rem 0;
    &:hover {
        color: lighten($body-color, 20%);
    }
    &Icon {
        align-items: center;
        background: #FFFFFF;
        border: 1px solid $gray-1;
        border-radius: 1.6rem;
        color: #C33764;
        display: flex;
        height: 3.2rem;
        justify-content: center;
        width: 3.2rem;
    }
    &Title {
        flex: 1;
        font-size: 1.8rem;
        font-weight: bold;
        margin-left: 1rem;
    }
    &Date {
        display: flex;
        align-items: center;
        font-size: 1.2rem;
    }
}

.utilities {
    &Search {
        position: relative;
        width: 100%;
        &Input {
            border-radius: 1rem;
            width: 100%;
            line-height: 3rem;
            padding: 0 4rem 0 1rem;
        }
        &Icon {
            position: absolute;
            top: 0.3rem;
            right: 1rem;
        }
    }
    &SortButton {
        display: inline-flex;
        justify-content: space-between;
        margin-top: 1rem;
        width: 100%;
    }
}

@include breakpoint-up(sm) {
    .addTaskBtn {
        margin: 2rem 0;
        &Content {
            height: 16.5rem;
        }
    }
    .utilities {
        align-items: center;
        display: flex;
        &Search {
            width: auto;
        }
        &SortButton {
            margin-top: 0;
            margin-left: 2rem;
            width: auto;
        }
    }
}
