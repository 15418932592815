@import '../../../../../../styles/variables.scss';

.completed {
    &Check {
        align-items: center;
        cursor: pointer;
        display: flex;
        margin: .5rem 0;
        svg {
            margin-right: .5rem;
        }
    }
}

.introAlert {
    padding: 1.5rem;
    background: lighten($gray-1, 5%);
    margin-bottom: 2rem;
    display: flex;
    align-items: center;
    svg {
        margin-right: 1rem;
    }
}