@import '../../../../styles/variables.scss';
@import '../../../../styles/mixins.scss';

.sidebar {
    &Content {
        margin-top: 2rem;
        background: #FAFAFA;
        border-radius: 1rem;
        padding: 2rem;
        text-align: center;
   
    }
    &Wtr {
        margin-top: 0;
    }
    &Actions {
        border-bottom: 1px solid #E1E1E1;
        margin: 0 -2rem 2rem -2rem;
        width: calc(100% + 4rem);
        padding: 0 2rem 2rem 2rem;
    }
}

@include breakpoint-up(lg) {
    .sidebar {
        max-width: 30rem;
        flex-basis: 30rem;
        flex-grow: 1;

    }
}


.settings {
    display: flex;
    flex: 1;
    justify-content: space-between;
    flex-direction: column;
    max-width: 100rem;

    &TitleInput:disabled {
        opacity: 1;
        padding: 0;
        border: none;
        margin-bottom: 0;
        color: $body-color !important;
    }
}


@include breakpoint-up(lg) {
    .settings {
        flex-direction: row;

        &Content {
            flex: 1;
            margin-right: 2rem;
        }
    }
}

.editorHolder {
    border: 1px solid lighten($gray-1, 7%);
    margin-bottom: 2rem;
    padding: 2rem;
}

.wtrNumberList {
   list-style-type:decimal;
   list-style-position: outside;
   padding-left: 2rem;
   li {
    margin-bottom: 1rem;
   }
}
.wtrPlanning {
    @include breakpoint-up(md) {
        display: grid;
        grid-template-columns: 1fr 1fr;
        margin: 2rem 0;
        gap: 2rem;
    }
    &Event {
        padding: 1.5rem;
        border-radius: 1rem;
        background: #E1E1E1;
    }
    &Todo {
        li {
            margin-bottom: 1rem;
            font-weight: bold;
            display: flex;
            align-items: center;
            svg {
                margin-right: 1rem;
                color: #C6CBB5;
            }
        }
    }
}
.wtrBookContents {
    @include breakpoint-up(md) {
        display: grid;
        grid-template-columns: 5fr 4fr;
        gap: 2rem
    }
    &List {
        list-style-type: disc;
        list-style-position: inside;
        margin-top: 2rem;
        li {
            margin-bottom: 0.5rem;
        }
    }
}

.wtrDownload {
    text-transform: uppercase;
    padding: 0.4rem 1.2rem;
    background: #fff;
    border: 1px solid $primary;
    color: $primary;
    font-weight: bold;
    border-radius: 1rem;
}

.wtrBrianImg {
    margin-bottom: -30px;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    max-width: 26rem;
}