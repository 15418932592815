@import '../../../../styles/variables.scss';
@import '../../../../styles/mixins.scss';

@include breakpoint-up(sm) {
    .book {
        padding-left: 8rem;
        &NoSide {
            padding-left: 0;
        }
    }
}
