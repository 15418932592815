@import '../../../../../styles/variables.scss';
@import '../../../../../styles/mixins.scss';

.pack {
    text-align: center;
    margin: 2rem 0;
    &Content {
        font-size: 3rem;
        margin-bottom: 0;
        border-radius: 1rem 1rem  0 0;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 3rem 1rem;
    }
    &Title {
        font-weight: bold;
        margin-bottom: 0;
    }
    &SubTitle {
        color: $body-color;
        padding: 1rem;
    }
    &Type {
        align-items: center;
        background: #FFFFFF;
        border: 1px solid $gray-1;
        border-radius: 2.1rem;
        box-shadow: 0 0.2rem .5rem 0 rgba(0, 0, 0, 0.16);
        color: #C33764;
        display: flex;
        height: 4.2rem;
        justify-content: center;
        left: calc(50% - 2.1rem);
        position: absolute;
        top: -2.1rem;
        width: 4.2rem;
    }

}

.bee {

background-image: url('../../../../../images/bee_bg.png'), linear-gradient(180deg, #EDEDED 0%, #EDEDED 0%, #A4A4A4 100%);
background-size: auto 100%;
background-repeat: no-repeat;
background-position-x: center;
}

@include breakpoint-up(sm) {
    .pack {
        &Content {
            min-height: 14rem;
        }
    }
}


//leavers banner
.leavers {
    &Content {
        font-size: 2.4rem;
        margin-bottom: 0;
        border-radius: 1rem 1rem 0 0;
        color: #fff;
        display: flex;
        align-items: center;
        padding: 3rem 1rem;
        background: url('../../../../../images/leavers-ad.png') no-repeat right top, url('../../../../../images/leavers-bg.png');
        background-size: contain, cover;
        padding-right: 140px;
        min-height: 14rem;
        text-align: left;
    }
    &Logo {
        max-width: 11rem;
        height: auto;
    }
}

