@import '../../../../styles/variables.scss';
@import '../../../../styles/mixins.scss';

.bookMenu {
    position: absolute;
    height: 6rem;
    width: 100%;
    top: 1rem;
    left: 0;
    z-index: 11;
    &Brand {
        cursor: pointer;
        border: 1px solid #fff;
        border-left: none;
        width: 8rem;
        padding: .4rem;
        border-radius: 0 2rem 2rem 0;
        &Toggle {
            width: 2rem;
            height: 2.2rem;
            display: inline-block;
            background-image: radial-gradient(circle, #fff 2px, transparent 3px);
            background-size: 100% 33.33%;
            vertical-align: middle;
        }
        img {
            max-width: 4rem;
            display: inline-block;
            vertical-align: middle;
        }
    }
    &Cover {
        img {
            max-width: 6rem;
            height: 6rem;
            margin: 1rem auto;
            border-radius: .5rem
        }
    }
    &Content,
    &Cover {
        display: none;
    }
    ul {
        padding: 1rem;
    }
    &MobileShown {
        display: block;
        background: #fff;
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        height: 100%;
        .title {
            background: #fff;
        }
        ul {
            width: 8rem;
            background: linear-gradient($primary-dark, $primary) no-repeat left top;
        }
    }
    &Inner {
        display: flex;
        flex-direction: row;
        min-height: 100%;
    }
    &MobileExtra {
        background: #fff;
        padding: 1rem;
        flex: 1 1;
        padding: 1.5rem;
        display: flex;
        flex-direction: column;
        &Top {
            display: flex;
            align-items: top;
            margin-bottom: 2rem;
            justify-content: space-between;
            align-items: flex-start;
        }
        &Img {
            flex-basis: 14rem;
            max-width: 14rem;
            border-radius: 2rem;
        }
        &Close {
            width: 4.8rem;
            height: 4.8rem;
            color: #fff;
            background: $primary-dark;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 5rem;
            cursor: pointer;
        }
    }
}
.chapter {
    cursor: pointer;
    font-size: 2rem;
    font-weight: bold;
    margin: 1rem 0;
    &Disabled {
        color: #ABABAB;
        pointer-events: none;
    }
}
.title {
    background: #fff;
    border-radius: 5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 4rem;
    &:hover {
        background: $primary;
        color: #fff;
    }
    &Selected {
        border-radius: 5rem 0 0 5rem;
        padding-right: 1rem;
		width: calc(100% + 1rem);
        &:hover {
            background: #fff;
            color: $body-color;
        }
    }
}


@include breakpoint-up(sm) {
    .bookMenu {
        width: 8rem;
        background: #E1E1E1;
        position: fixed;
        left: 10rem;
        height: 100%;
        top: 0;
        text-align: center;
        &Inner {
            display: block;
        }
        &Content,
        &Cover {
            display: block;
        }
        &MobileExtra,
        &Brand {
            display: none;
        }
    }
}

@media print {
    .bookMenu {
        display: none;
    }
}
