@import '/../../styles/variables.scss';
@import '/../../styles/mixins.scss';

.sidebar {
    &Content {
        align-items: center;
        display: flex;
        flex-direction: column;
        background: #FAFAFA;
        border-radius: 5rem;
        padding: 2rem;
        // margin-top: 2rem;
    }
    &Actions {
        width: 100%;
        &Title {
            font-size: 2.4rem;
            margin-bottom: 10px;
        }
        // &:not(:empty) {
        //     // margin-top: 2rem;
        // }
    }
}

@include breakpoint-up(lg) {
    .sidebar {
        max-width: 28rem;
        flex-basis: 28rem;
        flex-grow: 1;
        &Actions {
            width: auto;
        }
    }
}


.task {
    display: flex;
    flex: 1;
    justify-content: space-between;
    flex-direction: column;
    max-width: 80rem;
    &Content {
        flex: 1;
        
    }
    &FileUploadLabel {
        align-items: center;
        border: 1px solid $gray-2;
        border-radius: 1rem;
        cursor: pointer;
        display: flex;
        height: 3.7rem;
        justify-content: space-between;
        padding: 0 1rem;
        width: 100%;
    }
    &FileUploadForm {
        display: none;
    }
    &File {
        align-items: center;
        display: flex;
        margin-bottom: 1rem;
        width: 100%;
        &View,
        &Delete {
            cursor: pointer;
            color: $body-color;
            margin-left: 1rem;
        }
        &View {
            display: flex;
        }
        &Name {
            flex: 1;
            font-size: 1.8rem;
            font-weight: bold;
            margin-left: .5rem;
            overflow: hidden; 
            text-overflow: ellipsis;
            white-space: nowrap;
        }
        
    }
}

.scheduleForm, .labelSet, .labelSubSet {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 20px 0 0 0;
}

.labelSet {
    margin:0 0 20px 0;
    width: 300px;
}

.styledDate {
    border-radius: 5px;
    padding-left: 5px;
    margin-bottom: 5px;
    width: 170px;
}

.labelSubSet {
    flex-direction: row;
    margin: 0;
}

.timePicker {
    appearance: none;
    background-image: url('../../images/watch.png');
    background-size: 12px auto;
    background-repeat: no-repeat;
    background-position: right 2px center;
    border-radius: 5px;
    margin-left: 10px;
    padding: 0 17px 0 10px;
}

.startedAtSign {
    margin: 0 5px;
}

.editSchedule {
    cursor: pointer;
    font-weight: bold;
    text-decoration: underline;
}

@include breakpoint-up(lg) {
    .task {
        flex-direction: row;
        &Content {
            margin-right: 2rem;
        }
    }
}

.vocabField {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 1rem;
}

.vocabItem {
    background: $secondary;
    border-radius: 1rem;
    color: #fff;
    display: flex;
    margin: 0 0.5rem 0.5rem 0;
    align-items: center;
    font-size: 1.4rem;
    font-weight: 800;
    padding: .5rem 1rem;

    &Delete {
        appearance: none;
        padding: 0;
        border: none;
        margin-left: .8rem;
        width: 1.6rem;
        height: 1.6rem;
        line-height: 1.3;
        border-radius: 1rem;
        cursor: pointer;

        &:focus {
            outline: none;
        }

        &:hover {
            color: rgba(0, 0, 0, 0.4);
        }
    }
}