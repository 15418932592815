@import '../../../../../../styles/variables.scss';
@import '../../../../../../styles/mixins.scss';

.sidebar {
    &Content {
        margin-top: 2rem;
        background: #FAFAFA;
        border-radius: 1rem;
        padding: 2rem;
        text-align: center; 
    }
    &Actions {
        border-bottom: 1px solid #E1E1E1;
        margin: 0 -2rem 2rem -2rem;
        width: calc(100% + 4rem);
        padding: 0 2rem 2rem 2rem;
    }

    &PrintButtonIcon {
        margin-right: 0.5rem;
        position: relative;
        height: 1.6rem;
        top: 0.2rem;
        width: 1.6rem;
    }
}

.resources {
    li {
        font-weight: bold;
        font-size: 1.6rem;
        color: $body-color;
        margin: 1rem -2rem;
        width: calc(100% + 4rem);
        padding: 1rem 2rem;
        border-bottom: 1px solid #E1E1E1;

        &:last-child {
            margin-bottom: 0;
            padding-bottom: 0;
            border-bottom: none;
        }

        a {
            color: inherit;
        }
    }


    &Link {
        display: flex;
        align-items: center;
        text-align: left;
    }

    &Img {
        flex-basis: 10rem;
        max-width: 10rem;
        margin-right: 1rem;
        flex-shrink: 0;
        padding: 0 1.5rem;

        img {
            margin: auto;
            width: 100%;
        }
    }

}

@include breakpoint-up(lg) {
    .sidebar {
        max-width: 30rem;
        flex-basis: 30rem;
        flex-grow: 1;
    }
}

@media print {
    .sidebar {
        display: none;
    }
}
